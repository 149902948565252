import { useMemo } from 'react';

export const useGetProfilesByBranch = (branchId) =>
  useMemo(
    () => ({
      method: 'get',
      url: '/delivery/polish/profile',
      params: {
        branchId,
      },
    }),
    [branchId]
  );

export const useGetSalesmanByBranch = (branchId) =>
  useMemo(
    () => ({
      method: 'get',
      url: '/delivery/polish/salesman',
      params: {
        branchId,
      },
    }),
    [branchId]
  );

export const updateProfile = (planId, branchId, data) => {
  return {
    method: 'put',
    url: `/delivery/polish/profile/${planId}`,
    params: {
      branchId,
    },
    data: preProcessProfile(data),
  };
};

export const deleteProfile = (planId, branchId) => {
  return {
    method: 'delete',
    url: `/delivery/polish/profile/${planId}`,
    params: {
      branchId,
    },
  };
};

export const addProfile = (branchId, data) => {
  return {
    method: 'post',
    url: `/delivery/polish/profile`,
    params: {
      branchId,
    },
    data: preProcessProfile(data),
  };
};

const preProcessProfile = (profile) => {
  const tmp = { ...profile };
  tmp.vehicleTypes = profile.vehicleTypes.map((v) => {
    const tmpV = { ...v };

    // removing currently unsupported api properties from vehicle
    for (let key in tmpV) {
      if (
        [
          'id',
          'type',
          'minUtilization',
          'maxTrips',
          'vehicleAvailability',
          'contractType',
          'defaultSpeed',
          'fixedCostMaxDistance',
          'variableCost',
          'variableCostUnit',
          'notionalCostFactor',
          'maxHUs',
        ].includes(key)
      ) {
        delete tmpV[key];
      }
    }

    Object.entries(tmpV).forEach(([key, value]) => {
      if (key === 'name' || key === 'isSplitCompatible') return;
      tmpV[key] = parseFloat(value);
    });

    return tmpV;
  });
  delete tmp.id;
  return tmp;
};
