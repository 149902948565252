import produce from 'immer';
import { byId, remove, removeByIndex } from 'utils/utils';

const emptyNewProfile = {
  id: 'new',
  name: '',
  allSalesmen: true,
  data: {
    F: 60,
    U: 1,
    IL: 3,
    ChannelDictionary: {
      'MM 1': 600,
      'MM 2': 600,
      Hyper: 600,
      Super: 600,
      Speciality: 240,
      'New Pharmacy': 240,
      'Medium Beauty': 240,
      'Large A Beauty': 420,
      'Small A Beauty': 240,
      'WS Traditional': 600,
      'Medium Pharmacy': 240,
      'New Traditional': 240,
      'Large A Pharmacy': 420,
      'Large B Pharmacy': 420,
      'Small A Pharmacy': 240,
      'Medium Traditional': 240,
      'Large A Traditional': 420,
      'Large B Traditional': 420,
      'Small A Traditional': 240,
      'Small B Traditional': 240,
      'Small C Traditional': 240,
      'WS Beauty & Pharmacy': 600,
    },
  },
  default: false,
  salesmanIds: [],
  serviceTimeModel: 'provided',
  deliveryWeightConstraint: null,
  startTime: null,
  wave1Profile: null,
  vehicleTypes: [],
};

const emptyNewVehicle = {
  name: 'Tata Ace - Monthly',
  type: 'Tata Ace',
  maxWeight: 1000,
  maxVolume: 3500000,
  maxHUs: 100,
  volumeLimitRatio: 0.8,
  minUtilization: 0.3,
  maxTripTime: 36000,
  maxDropPoints: 35,
  maxTrips: 2,
  vehicleCount: 5,
  contractType: 'Monthly',
  defaultSpeed: 10,
  fixedCost: 1500,
  fixedCostMaxDistance: 100,
  variableCost: 10.5,
  variableCostUnit: 'KM',
  notionalCostFactor: 1,
  isSplitCompatible: Boolean(true),
};

export const initialState = {
  tabIndex: 0,
  accordionId: 0,
  profiles: [emptyNewProfile],
  ui: {},
};

export const reducer = (state, [action, data]) => {
  return produce(state, (draft) => {
    switch (action) {
      case a.INIT:
        draft.profiles = [emptyNewProfile, ...data];
        draft.profiles.forEach(({ id }) => {
          draft.ui[id] = { dirty: false };
        });
        break;

      case a.EDIT:
        {
          const { id, key, value } = data;
          byId(draft.profiles, id)[key] = value;
          draft.ui[id].dirty = true;
        }
        break;

      case a.REPLACE_PROFILE:
        {
          const { replaceProfile, updatedProfile } = data;
          draft.profiles[draft.profiles.findIndex((p) => p.id === replaceProfile)] = updatedProfile;
          draft.ui[updatedProfile.id] = { dirty: false };
        }
        break;

      case a.COPY_THIS_TO_NEW:
        {
          const { profileId } = data;
          const profile = byId(state.profiles, profileId);
          draft.profiles[draft.profiles.findIndex((p) => p.id === 'new')] = {
            ...profile,
            id: 'new',
            name: profile.name + ' COPY',
            default: false,
          };
        }
        break;

      case a.ADD_PROFILE:
        draft.profiles.push(data);
        draft.ui[data.id] = { dirty: false };
        draft.profiles[draft.profiles.findIndex((p) => p.id === 'new')] = emptyNewProfile;
        break;

      case a.DELETE_PROFILE:
        draft.profiles = remove(draft.profiles, data, 'id');
        break;

      case a.ADD_VEHICLE:
        byId(draft.profiles, data).vehicleTypes.push({ ...emptyNewVehicle });
        draft.ui[data].dirty = true;
        break;

      case a.EDIT_VEHICLE:
        {
          const { profileId, vehicleIndex, key, value } = data;
          byId(draft.profiles, profileId).vehicleTypes[vehicleIndex][key] = value;
          draft.ui[data.profileId].dirty = true;
        }
        break;

      case a.DELETE_VEHICLE:
        {
          const profile = byId(draft.profiles, data.profileId);
          profile.vehicleTypes = removeByIndex(profile.vehicleTypes, data.vehicleIndex);
          draft.ui[data.profileId].dirty = true;
        }
        break;

      case a.CHANGE_TAB_INDEX:
        {
          draft.tabIndex = data;
        }
        break;

      case a.CHANGE_ACCORDION_ID:
        {
          draft.accordionId = data;
        }
        break;

      default:
        return state;
    }
  });
};

export const a = {
  INIT: 'INIT',
  EDIT: 'EDIT',
  REPLACE_PROFILE: 'REPLACE_PROFILE',
  DELETE_PROFILE: 'DELETE_PROFILE',
  ADD_PROFILE: 'ADD_PROFILE',
  ADD_VEHICLE: 'ADD_VEHICLE',
  EDIT_VEHICLE: 'EDIT_VEHICLE',
  DELETE_VEHICLE: 'DELETE_VEHICLE',
  COPY_THIS_TO_NEW: 'COPY_THIS_TO_NEW',
  CHANGE_TAB_INDEX: 'CHANGE_TAB_INDEX',
  CHANGE_ACCORDION_ID: 'CHANGE_ACCORDION_ID',
};
