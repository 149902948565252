import React from 'react';
import Layout from 'components/Layout';
import { Router } from '@reach/router';
import { BranchPlanProfiles } from 'delivery/planProfile/PlanProfile';

export default function planProfile(props) {
  return (
    <Layout {...props} hideFilters>
      <Router>
        <BranchPlanProfiles path="/dl/planProfile/:branchId" />
      </Router>
    </Layout>
  );
}
