import { Box, Paper, Tab, Tabs, Zoom } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles, useTheme } from '@material-ui/styles';
import { navigate } from '@reach/router';
import FormDialog from 'components/FormDialog';
import GroupSelectLabel from 'components/GroupSelectLabel';
import {
  addProfile,
  useGetProfilesByBranch,
  useGetSalesmanByBranch,
} from 'delivery/services/profileService';
import React, { useCallback, useMemo, useReducer, useRef } from 'react';
import Select from 'react-select';
import { useGState } from 'state/store';
import { useAPI, useFetch, useReducerInit, useRemember } from 'utils/customHooks';
import { branchSelect as branchSelectList, byId } from 'utils/utils';
import { a, initialState, reducer } from './planProfileReducer';
import Profile from './Profile';

const onDefault = (storedValue) => {
  navigate(`/planProfile/${storedValue}`);
};

export default function PlanProfile({ branchId }) {
  const clients = useGState((state) => state.clients);
  const options = useMemo(() => branchSelectList(clients), [clients]);
  const storedBranchId = useRemember('profileBranch', branchId, [':branchId'], onDefault);

  const branchSelect = useMemo(
    () => (
      <Select
        options={options}
        value={options
          .flatMap((c) => c.options)
          .find(({ value }) => value === parseInt(storedBranchId))}
        onChange={({ value }) => navigate(`/planProfile/${value}`)}
        formatGroupLabel={GroupSelectLabel}
        placeholder="Select Branch ..."
        menuPortalTarget={document.getElementById('portal-target')}
      />
    ),
    [options, storedBranchId]
  );

  return (
    <>
      {branchSelect}
      {storedBranchId && <BranchPlanProfiles branchId={storedBranchId} />}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    top: theme.spacing(6),
    right: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  tabsPanel: {
    paddingRight: theme.spacing(6),
  },
}));

export function BranchPlanProfiles({ branchId }) {
  const classes = useStyles();
  const dialog = useRef();
  const theme = useTheme();
  const [profiles] = useFetch(useGetProfilesByBranch(branchId));
  const [salesman] = useFetch(useGetSalesmanByBranch(branchId));
  const [apiAction] = useAPI();
  const [state, dispatch] = useReducer(reducer, initialState);

  const openDialog = useCallback(() => {
    dialog.current.openDialog();
  }, [dialog]);

  useReducerInit(profiles, dispatch);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const disableDefault = useMemo(() => state.profiles.some((s) => s.default), [state.profiles]);

  const existingProfiles = state.profiles.filter((p) => p.id !== 'new');
  const visibleProfile = existingProfiles.length && existingProfiles[state.tabIndex];
  return (
    <Box p={1}>
      <FormDialog
        className={classes.addDialog}
        title="Create Plan Profile"
        content={
          <Profile
            profile={byId(state.profiles, 'new')}
            accordionId={state.accordionId}
            isNew
            disableDefault={disableDefault}
            salesman={salesman}
            dispatch={dispatch}
            createDialog
            profilesName={state.profiles.filter((p) => p.id !== 'new').map((p) => p.name)}
          />
        }
        ref={dialog}
        submit="CREATE"
        fullWidth
        maxWidth="lg"
        onSubmit={() => {
          apiAction(addProfile(branchId, byId(state.profiles, 'new')), (resp) =>
            dispatch([a.ADD_PROFILE, resp])
          );
        }}
      />
      {existingProfiles.length ? (
        <>
          <Paper>
            <Tabs
              className={classes.tabsPanel}
              value={state.tabIndex}
              onChange={(e, val) => dispatch([a.CHANGE_TAB_INDEX, val])}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {existingProfiles.map((profile, index) => {
                const label = profile.name ? profile.name : `Profile ${index + 1}`;
                return <Tab label={label} />;
              })}
            </Tabs>
          </Paper>
          <Profile
            key={visibleProfile.id}
            accordionId={state.accordionId}
            profile={visibleProfile}
            ui={state.ui[visibleProfile.id]}
            disableDefault={disableDefault}
            dispatch={dispatch}
            salesman={salesman || []}
            openDialog={openDialog}
            apiAction={apiAction}
            profilesName={existingProfiles.map((p) => p.name)}
          />
        </>
      ) : null}
      <Zoom
        in={true}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${transitionDuration.exitÍ}ms`,
        }}
        unmountOnExit
      >
        <Fab
          onClick={() => {
            dialog.current.openDialog();
          }}
          className={classes.fab}
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </Box>
  );
}
