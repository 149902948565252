import 'jsoneditor-react/es/editor.min.css';
import React from 'react';
import importedComponent from 'react-imported-component';

const getLoadable = (importFunction, loadingComponent) => {
  return importedComponent(importFunction, {
    LoadingComponent: loadingComponent || (() => <div />),
  });
};

const JsonEditor =
  typeof window !== `undefined`
    ? getLoadable(() =>
        Promise.all([
          import(/* webpackChunkName:'jsoneditor' */ 'jsoneditor-react'),
          import(/* webpackChunkName:'jsoneditor' */ 'brace'),
          import(/* webpackChunkName:'jsoneditor' */ 'brace/mode/json'),
          import(/* webpackChunkName:'jsoneditor' */ 'brace/theme/github'),
        ]).then(([{ JsonEditor: Editor }, ace]) => {
          return function EditorHoc(props) {
            return (
              <Editor
                ace={ace}
                navigationBar={false}
                mode="code"
                theme="ace/theme/github"
                {...props}
              />
            );
          };
        })
      )
    : null;

export default JsonEditor;
